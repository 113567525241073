export default {
    data() {
        return {
            count: 0,
            pauseTimer: null,
            scrollTimer: null
        }
    },
    methods: {
        // 自动滚动
        autoScroll() {
            const scrollHeight = document.getElementById("container").scrollHeight;
            const clientHeight = document.getElementById("container").clientHeight;
            const scroll = scrollHeight - clientHeight;
            // 滚动长度为0
            if (scroll === 0) {
                return;
            }
            // 触发滚动方法
            this.scrollFun();
            // 去除点击监听
            window.document.removeEventListener("touchstart", this.pauseScroll);
            // 重设点击监听
            window.document.addEventListener("touchstart", this.pauseScroll, false);
        },
        pauseScroll() {
            // 定时器不为空
            if (this.scrollTimer) {
                // 清除定时器
                clearInterval(this.scrollTimer);
                this.scrollTimer = null;
                // 一秒钟后重新开始定时器
                this.pauseTimer = setTimeout(() => {
                    this.scrollFun();
                }, 2000);
            }
        },
        scrollFun() {
            // 如果定时器存在
            if (this.scrollTimer) {
                // 则先清除
                clearInterval(this.scrollTimer);
                this.scrollTimer = null;
            }
            this.scrollTimer = setInterval(() => {
                const scrollHeight = document.getElementById("container").scrollHeight;
                const clientHeight = document.getElementById("container").clientHeight;
                const scroll = scrollHeight - clientHeight;
                // 获取当前滚动条距离顶部高度
                const scrollTop = document.getElementById("container").scrollTop;
                const temp = scrollTop + 1;
                document.getElementById("container").scrollTop = temp; // 滚动
                // 距离顶部高度  大于等于 滚动长度
                if (scroll <= temp) {
                    // 滚动到底部 停止定时器
                    clearInterval(this.scrollTimer);
                    this.scrollTimer = null;
                }
            }, 30);
        },
        closeAutoScroll() {
            clearTimeout(this.pauseTimer)
            this.pauseTimer = null
            clearInterval(this.scrollTimer)
            this.scrollTimer = null
            // 清理点击监听
            window.document.removeEventListener('touchstart', this.pauseScroll)
        }
    },
    mounted() {
        // let imgList = document.querySelectorAll("img");
        // imgList.forEach((item) => {
        //   this.count++;
        //   console.log(this.count);
        //   item.onload = () => {
        //     this.count--;
        //     console.log(this.count);
        //     if (this.count === 0) {
        //       this.autoScroll();
        //     }
        //   };
        // });
    },
    destroyed() {
        this.closeAutoScroll()
    },
}