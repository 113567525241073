<template>
  <div class="container newYear-border" id="container">
    <img mode="widthFix" src="@/assets/images/newYear/xn-1.gif" class="img1" />
    <div class="imgxn3And2">
      <img
        mode="widthFix"
        src="@/assets/images/newYear/xn-3.png"
        class="imgxn3"
      />
      <img mode="widthFix" :src="data[0].path" class="img2" />
    </div>
    <div class="one">
      <span :style="getStyle(data[1])" style="font-size: 32px" ref="text">{{
        data[1].content
      }}</span>
    </div>
    <img mode="widthFix" src="@/assets/images/newYear/xn-4.png" class="img4" />
    <div mode="widthFix" class="img5AndImg6 changeHeight">
      <img mode="widthFix" :src="data[2].path" class="img5" />
      <img mode="widthFix" :src="data[3].path" class="img6" />
    </div>
    <div class="two">
      <div class="text">
        <span :style="getStyle(data[4])" ref="text">{{ data[4].content }}</span>
      </div>
      <img mode="widthFix" src="@/assets/images/newYear/xn-7.png" />
    </div>
    <img mode="widthFix" src="@/assets/images/newYear/xn-8.png" class="img4" />
    <img mode="widthFix" :src="data[5].path" class="img9" />
    <div class="img10And11">
      <img mode="widthFix" :src="data[6].path" class="img10" />
      <img mode="widthFix" :src="data[7].path" class="img11" />
    </div>
    <div class="three">
      <span :style="getStyle(data[8])">{{ data[8].content }}</span>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/newYear/xn-12.png"
      class="img12"
    />
    <img mode="widthFix" :src="data[9].path" class="img13" />
    <div class="four">
      <span :style="getStyle(data[10])">{{ data[10].content }}</span>
      <img mode="widthFix" src="@/assets/images/newYear/xn-14.png" />
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/newYear/xn-15.png"
      class="img15"
    />
    <div mode="widthFix" class="img16AndImg17 changeHeight">
      <img mode="widthFix" :src="data[11].path" class="img16" />
      <img mode="widthFix" :src="data[12].path" class="img17" />
    </div>
    <div class="five">
      <span :style="getStyle(data[13])" style="margin-bottom: 20%">{{
        data[13].content
      }}</span>
      <span :style="getStyle(data[14])">{{ data[14].content }}</span>
    </div>
    <img mode="widthFix" src="@/assets/images/newYear/xn-18.png" class="img4" />
    <img mode="widthFix" :src="data[15].path" class="img19" />
    <img mode="widthFix" :src="data[16].path" class="img20" />
    <div class="six">
      <span :style="getStyle(data[17])">{{ data[17].content }}</span>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/newYear/xn-21.png"
      class="img21"
    />
    <img mode="widthFix" :src="data[18].path" class="img22" />
    <div class="seven">
      <div class="text">
        <span :style="getStyle(data[19])">{{ data[19].content }}</span>
      </div>
      <div class="leftYaxis"></div>
      <div class="leftXaxis"></div>
      <div class="rightYaxis"></div>
      <div class="rightXaxis"></div>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/newYear/xn-23.png"
      class="img23"
    />
  </div>
</template>

<script>
import newYearData from "@/assets/data/newYear";
import scroll from "@/mixins/scroll";
export default {
  mixins: [scroll],
  name: "NewYear",
  props: {
    list: {
      type: Array,
    },
    preview: {
      type: Boolean,
    },
  },
  data() {
    return {
      data: JSON.parse(JSON.stringify(newYearData)),
      // count: 0
    };
  },
  methods: {
    getStyle(data) {
      let percent =
        this.$route.name == "display" ||
        this.preview ||
        this.$route.name == "finished"
          ? 1
          : 0.6;
      return {
        fontFamily: data.fontFamily,
        fontSize: data.fontSize * percent + "px",
        color: data.fontColor,
        lineHeight: data.fontLineHeight * percent + "px",
        letterSpacing: data.fontSpacing * percent + "px",
        whiteSpace: "pre-wrap",
      };
    },

    // // 自动滚动
    // autoScroll() {
    //   const scrollHeight = document.getElementById("container").scrollHeight;
    //   const clientHeight = document.getElementById("container").clientHeight;
    //   const scroll = scrollHeight - clientHeight;
    //   // 滚动长度为0
    //   if (scroll === 0) {
    //     return;
    //   }
    //   // 触发滚动方法
    //   this.scrollFun();
    //   // 去除点击监听
    //   window.document.removeEventListener("click", this.pauseScroll);
    //   // 重设点击监听
    //   window.document.addEventListener("click", this.pauseScroll, false);
    // },
    // pauseScroll() {
    //   // 定时器不为空
    //   if (this.scrollTimer) {
    //     // 清除定时器
    //     clearInterval(this.scrollTimer);
    //     this.scrollTimer = null;
    //     // 一秒钟后重新开始定时器
    //     this.pauseTimer = setTimeout(() => {
    //       this.scrollFun();
    //     }, 2000);
    //   }
    // },
    // scrollFun() {
    //   // 如果定时器存在
    //   if (this.scrollTimer) {
    //     // 则先清除
    //     clearInterval(this.scrollTimer);
    //     this.scrollTimer = null;
    //   }
    //   this.scrollTimer = setInterval(() => {
    //     const scrollHeight = document.getElementById("container").scrollHeight;
    //     const clientHeight = document.getElementById("container").clientHeight;
    //     const scroll = scrollHeight - clientHeight;
    //     // 获取当前滚动条距离顶部高度
    //     const scrollTop = document.getElementById("container").scrollTop;
    //     const temp = scrollTop + 1;
    //     document.getElementById("container").scrollTop = temp; // 滚动
    //     // 距离顶部高度  大于等于 滚动长度
    //     if (scroll <= temp) {
    //       // 滚动到底部 停止定时器
    //       clearInterval(this.scrollTimer);
    //       this.scrollTimer = null;
    //     }
    //   }, 30);
    // },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        if (this.list) {
          this.data = this.list;
        }
      },
    },
  },
  // mounted() {
  //   // let imgList = document.querySelectorAll("img");
  //   // imgList.forEach((item) => {
  //   //   this.count++;
  //   //   console.log(this.count);
  //   //   item.onload = () => {
  //   //     this.count--;
  //   //     console.log(this.count);
  //   //     if (this.count === 0) {
  //   //       this.autoScroll();
  //   //     }
  //   //   };
  //   // });
  //   this.autoScroll()
  // },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
}
span {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 32px;
}
img {
  display: block;
}
.img1 {
  width: 62.8%;
  margin: auto;
}
.imgxn3And2 {
  position: relative;
  .imgxn3 {
    position: absolute;
    width: 40%;
    bottom: 5%;
    left: 30%;
  }
  .img2 {
    width: 100%;
    margin: 3% 0 10% 0;
  }
}

.img4 {
  width: 100%;
  margin-top: 6%;
  margin-bottom: 8%;
}
.img5AndImg6 {
  position: relative;
  width: 64.5%;
  padding: 35% 0;
  margin-left: 20%;
  margin-top: 30%;
  margin-bottom: 30%;
  border: 2px solid #a01d22;
}
.img5 {
  position: absolute;
  width: 90%;
  border: 8px solid #fff;
  right: 30%;
  top: -30%;
}
.img6 {
  position: absolute;
  width: 90%;
  border: 8px solid #fff;
  left: 30%;
  bottom: -30%;
}
.two {
  position: relative;
  width: 80%;
  margin: 20% auto;
}
.two .text {
  width: 60%;
  height: 30.7%;
}
.two .text span {
  text-align: left;
}
.two img {
  position: absolute;
  width: 62.3%;
  right: 0;
  top: 17.6%;
}
.img9 {
  width: 85.3%;
  margin: 5% auto 5%;
}
.img10And11 {
  display: flex;
  width: 85.3%;
  margin: 0 auto 6.5%;
  justify-content: space-between;
}
.img10 {
  width: 55%;
}
.img11 {
  width: 40%;
  border-top-left-radius: 500px;
  border-top-right-radius: 500px;
  margin-top: 17.2%;
}
.img12 {
  width: 100%;
  margin: 6% auto 15%;
}
.img13 {
  width: 75.6%;
  margin-left: 13.5%;
  margin-bottom: 9%;
  box-shadow: -15px -15px 0 0 rgba(255, 237, 219, 1);
}
.four {
  position: relative;
  width: 80%;
  margin-left: 8%;
}
.four span {
  text-align: left;
}
.four img {
  position: absolute;
  width: 60%;
  right: -10%;
  bottom: -50%;
}
.img15 {
  width: 87%;
  margin-top: 26%;
}
.img16AndImg17 {
  position: relative;
  width: 53%;
  padding: 32% 0;
  margin-left: 23.5%;
  margin-bottom: 33%;
  margin-top: 27.5%;
  border: 2px solid #a01d22;
}
.img16 {
  position: absolute;
  width: 96%;
  left: 30%;
  top: -30%;
}
.img17 {
  position: absolute;
  width: 96%;
  bottom: -30%;
  left: -30%;
}
.img19 {
  width: 74%;
  box-shadow: -15px -15px 0 0 rgba(255, 237, 219, 1);
  margin: 10% auto;
}
.img20 {
  width: 75%;
  box-shadow: 15px 15px 0 0 rgba(255, 237, 219, 1);
  margin: 6.5% 0 12% 15%;
}
.img21 {
  width: 100%;
  margin: 7.3% auto;
}
.img22 {
  width: 83%;
  margin: 0 auto 11%;
}
.seven {
  position: relative;
  width: 100%;
}
.seven .text {
  width: 62%;
  margin: auto;
}
.seven .leftYaxis {
  position: absolute;
  width: 0.7%;
  height: 60%;
  background-color: rgba(125, 29, 29, 1);
  left: 10%;
  bottom: -12%;
}
.seven .leftXaxis {
  position: absolute;
  width: 17%;
  height: 3%;
  background-color: rgba(125, 29, 29, 1);
  left: 7.5%;
  bottom: 0;
}
.seven .rightYaxis {
  position: absolute;
  width: 0.7%;
  height: 60%;
  background-color: rgba(125, 29, 29, 1);
  left: 90%;
  top: -7%;
}
.seven .rightXaxis {
  position: absolute;
  width: 17%;
  height: 3%;
  background-color: rgba(125, 29, 29, 1);
  right: 7.5%;
  top: -1%;
}
.img23 {
  width: 80%;
  margin: 11% auto 7%;
}
</style>